import { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { APP_ROUTES } from './pages/routes';

import './App.css';

function App() {
    return (
        <Routes>
            {APP_ROUTES.map(({ path, Element }) => {
                const routeKey = `${path}-route`;
                return (
                    <Route
                        key={routeKey}
                        path={path}
                        element={
                            <Suspense fallback={null}>
                                <Element />
                            </Suspense>
                        }
                    />
                );
            })}
        </Routes>
    );
}

export default App;
